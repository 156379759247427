<template>
  <label class="label-pswd">{{ title }}</label>
  <input
    class="form-control password login-input"
    :type="inputType"
    v-model="inputData"
    placeholder="********"
    @keyup="$emit('update:inputData', inputData)"
  />
  <img :src="icon" class="form-symbol cursor-pointer" @click="toggle" />
</template>

<script>
export default {
  props: ["title", "inputdata"],
  data: () => ({
    showPassword: false,
  }),
  computed: {
    icon() {
      if (this.showPassword) {
        return require("@/assets/images/show.svg");
      } else {
        return require("@/assets/images/hide.svg");
      }
    },

    inputType() {
      if (this.showPassword) {
        return "text";
      } else {
        return "password";
      }
    },
  },
  methods: {
    toggle() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>