<template>
  <router-view />
</template>

<script>
export default {
  created: function () {
    this.emitter.on('error', (error) => {
      this.$toast.error(error);
    })

    this.emitter.on('info', (text) => {
      this.$toast.info(text);
    })
  },
};
</script>