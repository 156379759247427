import { createApp } from "vue"

import mitt from "mitt"
import toaster from "@meforma/vue-toaster"
import vueMobileDetection from "vue-mobile-detection"

import App from "./App.vue"
import router from "./plugins/router"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import 'devextreme/dist/css/dx.light.css';

import "./assets/css/main.css"
import "./assets/css/normalize.css"

import "./assets/js/main";


const app = createApp(App).use(router).use(toaster).use(vueMobileDetection)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

import PasswordInput from "./components/PasswordInput.vue"
app.component("password-input", PasswordInput);

import { ContentLoader } from 'vue-content-loader';
app.component('ContentLoader', ContentLoader);

app.mount("#app")