import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: { name: 'home' },
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/home',
        alias: '/',
        name: 'home',
        component: () => import('../views/home/Index.vue'),
        meta: {
          title: 'Ana səhifə'
        }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/home/About.vue'),
        meta: {
          title: 'Haqqımızda'
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/home/Contact.vue'),
        meta: {
          title: 'Əlaqə'
        }
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/shared/Payment.vue'),
        meta: {
          title: 'Ödəniş'
        }
      },
      {
        path: '/partnership',
        name: 'partnership',
        component: () => import('../views/partnership/Index.vue'),
        meta: {
          title: 'Tərəfdaşlıq'
        }
      },
      {
        path: '/partnership/agent',
        name: 'partnership-agent',
        component: () => import('../views/partnership/Agent.vue'),
        meta: {
          title: 'Agentlər'
        }
      },
      {
        path: '/partnership/corporate',
        name: 'partnership-corporate',
        component: () => import('../views/partnership/Corporate.vue'),
        meta: {
          title: 'Koorperativ'
        }
      },
      {
        path: '/partnership/other',
        name: 'partnership-other',
        component: () => import('../views/partnership/Other.vue'),
        meta: {
          title: 'Digər'
        }
      },
      {
        path: '/ins',
        name: 'ins',
        component: () => import('../views/home/Insurance.vue'),
        meta: {
          title: 'Xidmətlər'
        }
      },
      {
        path: '/ins/cmtpl',
        name: 'ins-cmtpl',
        component: () => import('../views/home/CmtplInsurance.vue'),
        meta: {
          title: 'İcbari-avto'
        }
      },
      {
        path: '/travel',
        name: 'ins-travel',
        component: () => import('../views/travel/Index.vue'),
        meta: {
          title: 'Səfər sığortası'
        }
      },
      {
        path: '/cmtpl',
        name: 'ins-cmtpl',
        component: () => import('../views/cmtpl/Index.vue'),
        meta: {
          title: 'İcbari-avto'
        }
      },
      {
        path: '/cmtpl/success',
        name: 'cmtpl-success',
        component: () => import('../views/cmtpl/Success.vue'),
        meta: {
          title: 'Uğurlu'
        }
      },
      {
        path: '/az/icbari/success',
        name: 'cmtpl-success-az',
        component: () => import('../views/cmtpl/Success.vue'),
        meta: {
          title: 'Uğurlu'
        }
      },
      {
        path: '/cmtpl/fail',
        name: 'cmtpl-fail',
        component: () => import('../views/home/Failure.vue'),
        meta: {
          title: 'Xəta'
        }
      },
      {
        path: '/az/cmtpl/fail',
        name: 'cmtpl-fail-az',
        component: () => import('../views/home/Failure.vue'),
        meta: {
          title: 'Xəta'
        }
      },
      {
        path: '/profile',
        name: 'profile',
        redirect: { name: 'profile-home' },
        component: () => import('../views/profile/Main.vue'),
        children: [
          {
            path: '/profile/home',
            alias: '/profile',
            name: 'profile-home',
            component: () => import('../views/profile/Index.vue'),
            meta: {
              title: 'Şəxsi kabinet'
            }
          },
          {
            path: '/profile/get-insurance',
            name: 'profile-get-insurance',
            component: () => import('../views/profile/GetInsurance.vue'),
            meta: {
              title: 'Sığorta əldə et'
            }
          },
          {
            path: '/profile/ins/car',
            name: 'profile-car-insurance',
            component: () => import('../views/profile/insurance/cmtpl/Index.vue'),
            meta: {
              title: 'Avto-cmtpl'
            }
          },
          {
            path: '/profile/contracts/cmtpl/payment',
            name: 'profile-cmtpl-contracts-payment',
            component: () => import('../views/profile/insurance/cmtpl/Payment.vue'),
            meta: {
              title: 'Səyahət sığorta'
            }
          },
          {
            path: '/profile/ins/travel',
            name: 'profile-travel-insurance',
            component: () => import('../views/profile/insurance/travel/Index.vue'),
            meta: {
              title: 'Səyahət sığorta'
            }
          },
          {
            path: '/profile/contracts/travel/payment',
            name: 'profile-travel-contracts-payment',
            component: () => import('../views/profile/insurance/travel/Payment.vue'),
            meta: {
              title: 'Səyahət sığorta'
            }
          },
          {
            path: '/profile/contracts/:status',
            name: 'profile-contracts',
            redirect: { name: "profile-contracts-cmtpl" },
            component: () => import('../views/profile/contract/Main.vue'),
            children: [
              {
                path: '/profile/contracts/cmtpl/:status',
                name: 'cmtpl',
                component: () => import('../views/profile/contract/CmtplContracts.vue'),
                meta: {
                  title: 'Avto-cmtpl'
                }
              },
              {
                path: '/profile/contracts/travel/:status',
                name: 'travel',
                component: () => import('../views/profile/contract/TravelContracts.vue'),
                meta: {
                  title: 'Səyahət sığorta'
                }
              }
            ]
          },
          
          {
            path: '/profile/contracts/cmtpl/:status/:contractNumber',
            name: 'profile-contract-cmtpl',
            component: () => import('../views/profile/contract/CmtplContract.vue'),
            meta: {
              title: 'Müqavilə detalları'
            }
          },
          {
            path: '/profile/contracts/travel/:status/:policyNumber',
            name: 'profile-contract-travel',
            component: () => import('../views/profile/contract/TravelContract.vue'),
            meta: {
              title: 'Müqavilə detalları'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/home/Failure.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  beforeEach(to, from, next) {
  }
})

router.beforeEach((to, from) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  document.title = nearestWithTitle?.meta?.title || 'Mobins';
  return true;
})

export default router