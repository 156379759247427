document.querySelectorAll(".banner-tab-content-container .form-group").forEach(function (el) {
    el.lastElementChild.addEventListener('focus', function (e) {
        el.firstElementChild.style.color = "#343a40";
    });

    el.lastElementChild.addEventListener('focusout', function (e) {
        el.firstElementChild.style.color = "#878C98";
    });
});

document.querySelectorAll(".faq-tab-item").forEach(function (el) {

    el.firstElementChild.addEventListener('click', function (e) {

        console.log("Test");

        let imgElement = el.firstElementChild.lastElementChild;
        let companyImg = el.firstElementChild.firstElementChild;

        if (imgElement.src.includes("plus")) {
            imgElement.src = imgElement.src.replace("plus", "minus");
            if (companyImg.tagName === 'IMG')
                companyImg.style.filter = "grayscale(0%)";
        } else {
            imgElement.src = imgElement.src.replace("minus", "plus");
            if (companyImg.tagName === 'IMG')
                companyImg.style.filter = "grayscale(100%)";
        }
    });
});

document.querySelectorAll(".about-accordion").forEach(function (el) {
    let rotateDeg = 0;

    el.addEventListener('click', function (e) {
        document.querySelector("#about-accordion-icon").style.transform = `rotate(${rotateDeg += 180}deg)`;
    });
});

window.addEventListener('scroll', (e) => {
    const nav = document.querySelector('.app-navbar');
    if (nav) {
        if (window.pageYOffset > 0) {
            nav.classList.add("shadow-sm");
        } else {
            nav.classList.remove("shadow-sm");
        }
    }
    
});

document.querySelector("body").addEventListener('click', () => {
    let mainNav = document.querySelector("#nav");
    let wcNav = document.querySelector("#wc-nav");

    if (mainNav && mainNav.classList.contains("show"))
        mainNav.classList.remove("show");

    if (wcNav && wcNav.classList.contains("show"))
        wcNav.classList.remove("show");
});


